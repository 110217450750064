function lightbox () {
    const lightboxBtns = document.querySelectorAll('.lightbox-btn');

    if(lightboxBtns.length > 0) {
        lightboxBtns.forEach(el=>{
            el.addEventListener('click', (e)=> {e.preventDefault()});
        });

        let lightbox = GLightbox({
            selector: '.lightbox-btn',
            title: false,
            plyr: {
                config: {
                    controls: false,
                    youtube: {
                        showinfo: 1,
                        controls: true,
                    },
                }
            }
        }); 
    }
}

export default lightbox;