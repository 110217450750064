function scroll () {
    /*=======
    Painted header to scroll
    ========*/
    const header = document.querySelector('.header');

    if(header) {
        const homeHero = document.querySelector('.home__hero');

        if(homeHero) {
            document.addEventListener('scroll', ()=> {
                window.scrollY >= window.innerHeight ? header.classList.add('painted') : header.classList.remove('painted');
            });
        } else {
            document.addEventListener('scroll', ()=> {
                window.scrollY >= 10 ? header.classList.add('painted') : header.classList.remove('painted');
            });
        }
    }


    /*=======
    Anim categories on home page
    =======*/
    const categoriesSection = document.querySelectorAll('.home__categories-section');

    if(categoriesSection.length > 0) {
        setTimeout(()=> {
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const categoriesSectionH = entry.target.offsetTop - window.innerHeight;
    
                        const toLeft = entry.target.querySelectorAll('[data-brandRow="toLeft"]'),
                            toRight = entry.target.querySelectorAll('[data-brandRow="toRight"]');
    
                        if(window.innerWidth >= 768) {
                            document.addEventListener('scroll', (e)=> {
                                toLeft.forEach(el=>{el.style.transform = `translateX(-${((window.scrollY - categoriesSectionH) / 5)}px)`});
                                toRight.forEach(el=>{el.style.transform = `translateX(${((window.scrollY - categoriesSectionH) / 5)}px)`});
                            });
                        } else {
                            document.addEventListener('scroll', (e)=> {
                                toLeft.forEach(el=>{el.style.transform = `translateX(-${((window.scrollY - categoriesSectionH) / 1.5)}px)`});
                                toRight.forEach(el=>{el.style.transform = `translateX(${((window.scrollY - categoriesSectionH) / 1.5)}px)`});
                            });
                        }
                        
                    }
                });
            }, {});
            categoriesSection.forEach(el => observer.observe(el));
        }, 1500);
    }


    /*=======
    Hide hero on Home page
    =======*/
    const homeHero = document.querySelector('.home__hero');

    if(homeHero) {
        //Remove the background image if the user started the page below the first screen
        if(window.scrollY > window.innerHeight) homeHero.style.opacity = '0';

        //Customize display/hide background image when scrolling with conditions
        document.addEventListener('scroll', ()=> {
            window.scrollY >= window.innerHeight ? homeHero.style.opacity = '0' : homeHero.style.opacity = '1';
        });
    }


    /*=======
    Hide background img on project page
    =======*/
    const projectImg = document.querySelector('.project__back');

    if(projectImg && window.innerWidth >= 768) {
        const footer = document.querySelector('.footer__section');
        const footerDistance = footer.offsetTop;

        //Remove the background image if the user started the page below the team's section
        if(window.scrollY > footerDistance) projectImg.style.opacity = '0';

        document.addEventListener('scroll', ()=> {
            window.scrollY >= (footerDistance - footer.offsetHeight) ? projectImg.classList.add('hide') : projectImg.classList.remove('hide');
        });
    }
}

export default scroll;