function nav () {
    const navWrapper = document.querySelector('.nav');

    if(navWrapper) {
        const burger = document.querySelector('.header__burger');
        const navList = document.querySelector('.nav__list');
        const navInfo = document.querySelector('.nav__info');
        const navClose = document.querySelector('.nav__close');
        const navLinks = document.querySelectorAll('.nav__link');

        burger.addEventListener('click', ()=> {
            navWrapper.classList.add('show');

            setTimeout(()=> {
                navList.classList.add('show');
                navInfo.classList.add('show');
            }, 600);
        });

        function closeNav() {
            navList.classList.remove('show');
            navInfo.classList.remove('show');
            
            setTimeout(()=> {
                navWrapper.classList.remove('show');
            }, 300);
        }

        navClose.addEventListener('click', ()=> {
            closeNav();
        });


        navLinks.forEach(el=> {
            el.addEventListener('click', ()=> {
                closeNav();
            });
        });
    }
}

export default nav;