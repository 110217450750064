function anim () {
    const animEl = document.querySelectorAll('[data-animToScroll]');
    
    if(animEl.length > 0) {
        animEl.forEach(el=> {
            const observerAnim = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if(entry.isIntersecting) {
                        let time = entry.target.getAttribute('data-time');
    
                        if(!entry.target.getAttribute('data-time')) {
                            entry.target.classList.add('anim');
                        } else {
                            setTimeout(()=> {
                                entry.target.classList.add('anim');
                            }, time);
                        }
                    }
                });
            }, {threshold: [.3]});
            observerAnim.observe(el);
        });
    }

    
    const animInstantlyEl = document.querySelectorAll('[data-animInstantly]');

    if(animInstantlyEl.length > 0) {
        animInstantlyEl.forEach(el=> {
            let time = el.getAttribute('data-time');

            setTimeout(()=> {
                el.classList.add('anim');
            }, time);
        });
    }
}

export default anim;