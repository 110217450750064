import scroll from "./modules/scroll";
import anim from "./modules/anim";
import nav from "./modules/nav";
import slider from "./modules/slider";
import anchors from "./modules/anchors";
import lightbox from "./modules/lightbox";
import forms from "./modules/forms";

window.addEventListener('DOMContentLoaded', ()=> {
    scroll();
    anim();
    nav();
    slider();
    anchors();
    lightbox();
    forms();
});