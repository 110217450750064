function forms () {
    const popupSuccess = document.querySelector('.popup-success');

    //Отслеживаем успешную отправку формы обратной связи
    document.addEventListener('wpcf7mailsent', function( event ) {
        setTimeout(()=> {
            popupSuccess.classList.add('show');

            setTimeout(()=> {
                popupSuccess.classList.remove('show');
            }, 3500);
        }, 500);
    }, false );


    //Отслеживаем неудачную отправку формы обратной связи
    document.addEventListener('wpcf7mailfailed', function( event ) {
        alert('An unexpected error has occurred. Please check your internet connection or try again later.');
    }, false );


    // Используем регулярное выражение для удаления всех символов, кроме цифр и "+" в телефоне
    document.addEventListener('input', function(e) {
        const input = e.target;

        if(input && input.classList.contains('form__input--tel')) {
            //Создаем маску и Обновляем значение в поле ввода
            input.value = input.value.replace(/[^0-9+]/g, '');
        }
    });
}

export default forms;