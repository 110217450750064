function slider () {
    /**
     * Slider for projects
     */
    const sliderProjects = document.querySelector('.slider-projects');

    if(sliderProjects) {
        let slider = new Swiper(sliderProjects, {
            grabCursor: true,
            breakpoints: {
                1: {
                    spaceBetween: 20,
                    slidesPerView: 1,
                    speed: 600,
                },
                767: {
                    spaceBetween: 30,
                    freeMode: {
                        enabled: true,
                    },
                    slidesPerView: "auto",
                },
            }
        });
    }


    /**
     * Slider on about page
     */
    const sliderAbout = document.querySelector('.about__slider');

    if(sliderAbout && window.innerWidth >= 1261) {
        const sliderNav = document.querySelector('.about__slider-nav-wrapper');
        const slidesTitle = document.querySelector('.about__slider-title');

        let slider = new Swiper(sliderAbout, {
            freeMode: false,
            simulateTouch: false,
            speed: 800,
            loop: true,
            allowSlidePrev: false,
            on: {
                init: function () {
                    sliderNav.classList.add('disabled');
                },
                slideChange() {
                    this.allowSlidePrev = this.realIndex !== 0;
                },
                slideChangeTransitionEnd() {
                    this.realIndex === 0 ? sliderNav.classList.add('disabled') : sliderNav.classList.remove('disabled');

                    if(this.realIndex === 0)
                        slidesTitle.innerText = 'Click to discover';
                    if(this.realIndex === 1)
                        slidesTitle.innerText = 'The Idea';
                    if(this.realIndex === 2)
                        slidesTitle.innerText = 'First Office';
                    if(this.realIndex === 3)
                        slidesTitle.innerText = 'Business strategy';
                    if(this.realIndex === 4)
                        slidesTitle.innerText = 'Watchword';
                },
            },
            mousewheel: {
                invert: false,
                sensitivity: 10,
                thresholdDelta: 10,
            },
            allowTouchMove: false,
            effect: "creative",
            creativeEffect: {
                prev: {
                        shadow: false,
                        translate: [0, "-10%", -1],
                    },
                next: {
                    translate: [0, "100%", 0],
                },
            },
            navigation: {
                nextEl: ".about__slider-nav-btn--next",
                prevEl: ".about__slider-nav-btn--prev",
            },
        });
    }
}

export default slider;