function anchors() {
    const anchorLinks = document.querySelectorAll('.anchorLink');

    if(anchorLinks.length > 0) {
        let speed = .4;

        anchorLinks.forEach(el => {
            el.addEventListener('click', function(e) {
                let hash = decodeURIComponent(this.getAttribute('href')).replace('#', '');
                let targetElement = document.getElementById(hash);
                const headerH = document.querySelector('.header').offsetHeight;

                if(targetElement) {
                    e.preventDefault();

                    let t = targetElement.getBoundingClientRect().top;
                    let w = window.pageYOffset;
                    let start = null;

                    requestAnimationFrame(step);

                    function step(time) {
                        if (start === null) start = time;

                        let progress = time - start,
                            r = (t < 0 ? Math.max(w - progress / speed, w + t) : Math.min(w + progress / speed, w + t));

                        setTimeout(()=> {
                            window.scrollTo(0, r);
                        }, 10);

                        if (r != w + t) {
                            requestAnimationFrame(step);
                        } else {
                            location.hash = '#' + hash;
                        }
                    }
                } else {
                    window.location.replace('/' + '#' + hash);
                }
                
            }, false);
        });
    }
}
    
export default anchors;